import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { PageLayout, Content, Table } from '../components/PageLayout.tsx';
import styled from 'styled-components';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PageLayout('Support Matrix');
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Content mdxType="Content">
      <p>{`The table below lists the devices that are currently supported. Contact us if you'd like a specific device to be added.`}</p>
      <Table mdxType="Table">
        <tr>
  <th>Device / Operating System</th>
  <th>Official Support</th>
  <th>Automated Install</th>
        </tr>
        <tr>
  <td>Windows</td>
  <td data-green>Yes</td>
  <td data-green>Yes</td>
        </tr>
        <tr>
  <td>Mac OS X</td>
  <td data-green>Yes</td>
  <td data-green>Yes</td>
        </tr>
        <tr>
  <td>Ubuntu</td>
  <td data-green>Yes</td>
  <td data-green>Yes</td>
        </tr>
        <tr>
  <td>Android</td>
  <td data-green>Yes</td>
  <td data-green>Yes</td>
        </tr>
        <tr>
  <td>iOS</td>
  <td data-green>Yes</td>
  <td data-green>Yes</td>
        </tr>
        <tr>
  <td>Playstation 2</td>
  <td data-grey>-</td>
  <td data-grey>-</td>
        </tr>
        <tr>
  <td>Playstation 3</td>
  <td data-red>No</td>
  <td data-red>No</td>
        </tr>
        <tr>
  <td>Playstation 4</td>
  <td data-red>No</td>
  <td data-red>No</td>
        </tr>
        <tr>
  <td>Xbox</td>
  <td data-red>No</td>
  <td data-red>No</td>
        </tr>
        <tr>
  <td>Xbox 360</td>
  <td data-red>No</td>
  <td data-red>No</td>
        </tr>
        <tr>
  <td>Xbox One</td>
  <td data-red>No</td>
  <td data-red>No</td>
        </tr>
        <tr>
  <td>Wii</td>
  <td data-red>No</td>
  <td data-red>No</td>
        </tr>
        <tr>
  <td>Wii U</td>
  <td data-yellow>Some</td>
  <td data-red>No</td>
        </tr>
        <tr>
  <td>Nintendo DS</td>
  <td data-red>No</td>
  <td data-red>No</td>
        </tr>
        <tr>
  <td>Nintendo 3DS</td>
  <td data-yellow>Some</td>
  <td data-red>No</td>
        </tr>
      </Table>
    </Content>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      